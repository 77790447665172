export default {
    data() {
        return {
            feature: [{
                title: '显示模式丰富多样，功能强大',
                numberPic: require("../../assets/icons/num1.png"),
                text: '系统内置标准班级桌面、迎宾模式、通知模式、活动宣传、网页投放、考试发布、考勤模式、ppt模式、系统新闻、物联操控、智慧生态、积分兑奖、数据看板等众多模式。'
            }, {
                title: '可视化生成屏幕桌面，便捷高效',
                numberPic: require("../../assets/icons/num2.png"),
                text: '系统内置完整的班牌桌面组件体系，用户可以通过各种规格和用途的组件通过十二宫格来实现百变的班牌个性化界面，通过对组件设置，可以实现用户的绝大部分使用场景。另外系统内置常用模版，让用户使用更便捷，系统支持不同场景下使用不同的模版。'
            }, {
                title: '无缝对接智慧校园，展示模式多样性',
                numberPic: require("../../assets/icons/num3.png"),
                text: '班级桌面可全面展示校园通知、校园活动、课程信息、班级荣誉、班级评比、班级风采、考场信息等数十种组件信息，同时可以将系统在校内其他显示终端显示、甚至访客机、储物柜、售货机，系统数据和智慧校园移动端无缝连接。'
            }, {
                title: '校园大数据看板，轻松统揽全局',
                numberPic: require("../../assets/icons/num4.png"),
                text: '融合来自于我司校园各类管理应用、各类智能传感器、第三方应用等多维度、多模态的数据，形成物联管控中心、校园安全中心、教育管理中心、学生成长画像、校园大数据中心等数据看板。'
            }, {
                title: '系统拓展丰富，数据呈现更精彩',
                numberPic: require("../../assets/icons/num5.png"),
                text: '系统具有超强兼容性，部分组件具备第三方数据接入功能，可以方便接入第三方的数据，同时系统配套校帮智屏推送盒，适用非智能显示设备，系统拓展性更强。'
            }, {
                title: '智慧屏幕移动端应用多端适配',
                numberPic: require("../../assets/icons/num6.png"),
                text: '智慧屏幕移动端提供App、微信公众号、小程序、企业微信、钉钉等接入融合，具备良好的兼容性和适用性。'
            }],
        }
    },
    created() {

    },
}