<template>
  <div class="xbzp">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>智慧屏幕系统</h1>
          <p><a href="/">首页</a>
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />我们的优势
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />智慧屏幕系统
          </p>
        </div>
      </div>
      <div class="auto-container">
        <section class="rules-section">
          <div class="auto-container">
            <Row>
              <Col :xl="12" :lg="12">
              <div class="inner-column" style="padding: 0">
                <div class="image">
                  <a href="#"><img
                      src="../../assets/resource/xbzp.png"
                      alt=""
                    /></a>
                </div>
              </div>
              </Col>

              <Col :xl="12" :lg="12">
              <div class="inner-column">
                <div class="sec-title">
                  <div class="title-icon">
                    <span class="icon"><img
                        src="../../assets/icons/separater.png"
                        alt=""
                      /></span>
                  </div>
                  <h2>智慧屏幕系统</h2>
                </div>
                <div class="text">
                  <p>校帮智慧屏幕管理系统融合了校园大数据看板、媒体信息发布终端、班级管理终端、智慧考勤测温、家校互通平台、物联管控终端、智慧环境终端、学生成长评价、访客登记、智能储物终端、画屏终端等一系列功能，是校园管理中非常重要的得力助手。</p>
                </div>
              </div>
              </Col>
            </Row>
          </div>
        </section>

        <Row style="padding-bottom:80px">
          <Col
            :xl="8" :lg="8" :md="12" :sm="24"
            v-for="(item,index) in feature"
            :key="index"
          >
          <div class="feature-block-two">
            <div class="inner-box ">
              <div class="icon-box">
                <img :src="item.numberPic" alt="" />
              </div>
              <h3>{{item.title}}</h3>
              <div class="text">{{item.text}}</div>
            </div>
          </div>
          </Col>
        </Row>


        <div class="sec-title">
          <div class="title-icon">
            <span class="icon"><img src="../../assets/icons/separater.png" alt="" /></span>
          </div>
          <h2>图集</h2>
        </div>
        <Carousel v-model="value" loop autoplay dots="none" arrow="always" autoplay-speed="5000">
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/xbzp1.png" alt="" srcset="">
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/xbzp2.png" alt="" srcset="">
            </div>
          </CarouselItem>
        </Carousel>
      </div>
    </div>
  </div>
</template>
<script>
import xbzp from "./xbzp.js";
export default xbzp;
</script>

<style lang="less" scoped>
@import "./xbzp.less";
</style>